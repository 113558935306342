import React from 'react';
import './Hero.css';

const Hero = () => {
    const handleScrollToServices = (e) => {
        e.preventDefault();
        const servicesSection = document.getElementById('services');
        if (servicesSection) {
            servicesSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
    <section className="hero">
        <div className="hero-content">
            <div className="promo-banner">
                -20% sur votre première réservation !
            </div>
            <h1>Time To Clean Metz</h1>
            <h2 style={{ fontSize: "2.5em" }}>Nettoyage écologique professionnel</h2>
            <p>
                Des solutions de nettoyage respectueuses de l'environnement pour votre maison et votre voiture.
                Découvrez la différence d'un nettoyage professionnel et éco-responsable avec Time To Clean.
            </p>
            <div className="hero-cta">
                <a href="#services" className="cta-button" onClick={handleScrollToServices}>
                    Réserver maintenant
                </a>
                <a href="/contact" className="cta-button cta-secondary">
                    Nous contacter
                </a>
            </div>
        </div>
    </section>
);

};

export default Hero;
