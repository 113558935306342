import React, { useState } from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaInstagram } from 'react-icons/fa';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://backend.time-to-clean.com/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Une erreur est survenue');
            }

            // ✅ Succès
            console.log('Message envoyé avec succès:', data);
            alert('Votre message a bien été envoyé !');

            // ✅ Réinitialisation du formulaire
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });

        } catch (err) {
            console.error('Erreur envoi formulaire contact:', err);
            alert('Erreur lors de l\'envoi du message. Veuillez réessayer.');
        }
    };

    return (
        <section className="contact-section">
            <div className="contact-container">
                <div className="contact-info">
                    <h2>Contactez-nous</h2>
                    <p>
                        Nous sommes là pour répondre à toutes vos questions. N'hésitez pas à nous contacter
                        pour un devis personnalisé ou plus d'informations sur nos services.
                    </p>

                    <div className="contact-methods">
                        <div className="contact-method">
                            <div className="method-icon">
                                <FaPhone />
                            </div>
                            <div className="method-info">
                                <h3>Téléphone</h3>
                                <p>06 25 63 74 98</p>
                            </div>
                        </div>

                        <div className="contact-method">
                            <div className="method-icon">
                                <FaEnvelope />
                            </div>
                            <div className="method-info">
                                <h3>Email</h3>
                                <p>contact@time-to-clean.com</p>
                            </div>
                        </div>

                        <div className="contact-method">
                            <div className="method-icon">
                                <FaMapMarkerAlt />
                            </div>
                            <div className="method-info">
                                <h3>Adresse</h3>
                                <p>6 rue Saint Vincent, Woippy 57140</p>
                            </div>
                        </div>
                    </div>

                    <div className="social-links">
                        <a href="https://facebook.com" className="social-link" target="_blank" rel="noopener noreferrer">
                            <FaFacebook />
                        </a>
                        <a href="https://www.instagram.com/timetoclean_metz" className="social-link" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                    </div>
                </div>

                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name" className="form-label">Nom</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-input"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-input"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="subject" className="form-label">Sujet</label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            className="form-input"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            className="form-textarea"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>

                    <button type="submit" className="submit-button">
                        Envoyer le message
                    </button>
                </form>
            </div>
        </section>
    );
};

export default Contact;

