import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const sections = [
        {
            title: 'Time To Clean Metz',
            content: [
                React.createElement('p', { key: 'desc' }, 'Nettoyage Écologique'),
                React.createElement('p', { key: 'year' }, `© ${currentYear} Tous droits réservés`)
            ]
        },
        {
            title: 'Contact',
            content: [
                React.createElement('p', { key: 'email' }, 'contact@time-to-clean.com'),
                React.createElement('p', { key: 'phone' }, '06 25 63 74 98'),
                React.createElement('p', { key: 'address' }, '6 rue Saint Vincent, Woippy 57140')
            ]
        },
        {
            title: 'Suivez-nous',
            content: [
                React.createElement('div', { key: 'social', className: 'social-links' }, [
                    React.createElement('a', { key: 'ig', href: 'https://www.instagram.com/timetoclean_metz', target: '_blank', rel: 'noopener noreferrer' }, 'Instagram'),
                ])
            ]
        }
    ];

    const legalLinks = [
	React.createElement('a', { key: 'legal', href: '/mentions-legales', className: 'footer-link' }, 'Mentions légales'),
	React.createElement('a', { key: 'privacy', href: '/politique-confidentialite', className: 'footer-link' }, 'Politique de confidentialité')

    ];

const footerSections = sections.map((section, index) =>
    React.createElement(
        'div',
        { key: index, className: 'footer-section' },
        [React.createElement('h3', { key: 'title', className: 'footer-title-color footer-title' }, section.title)].concat(section.content)
    )
);

    return React.createElement('footer', { className: 'footer' },
        [
            React.createElement('div', { key: 'main', className: 'footer-content' }, footerSections),
            React.createElement('div', { key: 'legal', className: 'legal-links' }, legalLinks)
        ]
    );
};

export default Footer;
