import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import './FAQ.css';

const faqs = [
    {
        id: 1,
        category: "Services Auto",
        question: "Quels types de véhicules nettoyez-vous ?",
        answer: "Time To Clean nettoie tous types de véhicules : voitures particulières, SUV, utilitaires, motos. Notre équipe est formée pour traiter chaque véhicule avec le soin approprié."
    },
    {
        id: 2,
        category: "Services Maison",
        question: "Utilisez-vous des produits écologiques ?",
        answer: "Oui, Time To Clean utilise exclusivement des produits écologiques et biodégradables. Notre engagement pour l'environnement est au cœur de notre service."
    },
    {
        id: 3,
        category: "Réservation",
        question: "Comment puis-je réserver un service ?",
        answer: "Vous pouvez réserver facilement en ligne via notre site web, par téléphone, ou par email. Nous confirmerons votre réservation dans les plus brefs délais."
    },
    {
        id: 4,
        category: "Prix",
        question: "Quels sont vos tarifs ?",
        answer: "Nos tarifs varient selon le service et les besoins spécifiques. Nous proposons des devis personnalisés gratuits. Contactez-nous pour plus d'informations."
    },
    {
        id: 5,
        category: "Services",
        question: "Quelle est la durée moyenne d'une intervention ?",
        answer: "La durée varie selon le service : environ 2h pour un nettoyage auto standard, 3-4h pour un nettoyage complet de canapé. Nous vous donnerons une estimation précise lors de votre réservation."
    }
];

const FAQ = () => {
    const [activeId, setActiveId] = useState(null);

    const toggleFaq = (id) => {
        setActiveId(activeId === id ? null : id);
    };

    return (
        <section className="faq-section">
            <div className="faq-container">
                <div className="faq-header">
                    <h2>Questions Fréquentes</h2>
                    <p>Trouvez rapidement les réponses à vos questions</p>
                </div>

                <div className="faq-list">
                    {faqs.map((faq) => (
                        <div 
                            key={faq.id}
                            className={`faq-item ${activeId === faq.id ? 'active' : ''}`}
                        >
                            <button
                                className="faq-question"
                                onClick={() => toggleFaq(faq.id)}
                                aria-expanded={activeId === faq.id}
                            >
                                <span>
                                    <span className="faq-category">{faq.category}</span>
                                    {faq.question}
                                </span>
                                <span className="faq-icon">
                                    <FaPlus />
                                </span>
                            </button>
                            <div className="faq-answer">
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="contact-cta">
                    <h3>Vous avez d'autres questions ?</h3>
                    <p>Notre équipe est là pour vous aider et répondre à toutes vos questions.</p>
                    <Link to="/contact" className="contact-button">
                        Contactez-nous
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
