import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';
import Services from './Services';
import FAQ from './FAQ';
import Contact from './Contact';
import Booking from './Booking';
import './Header.css';
import './Footer.css';
import './Hero.css';
import './Services.css';
import './FAQ.css';
import './Contact.css';
import './Booking.css';
import './App.css';

const App = () => {
    const handleContactSubmit = (formData) => {
        console.log('Form submitted:', formData);
        // Ici nous ajouterons plus tard la logique d'envoi d'email
    };

    return (
        <Router>
            <div className="app">
                <Header />
                <main className="main-content">
                    <Routes>
                        <Route path="/" element={
                            <>
                                <Hero />
				<Services />
                                <Contact onSubmit={handleContactSubmit} />
                                
				<FAQ />
				
                            </>
                        } />
                        <Route path="/contact" element={<Contact onSubmit={handleContactSubmit} />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/booking" element={<Booking />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
