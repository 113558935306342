import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

// Importation des logos
import logoDark from '../assets/logo-dark.png';
import logoLight from '../assets/logo-light.png';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return React.createElement(
        'header',
        { className: `header ${isScrolled ? 'scrolled' : ''}` },
        React.createElement(
            'div',
            { className: 'header-content' },
            React.createElement(
                Link,
                { to: '/', className: 'logo', onClick: closeMenu },
                React.createElement('img', {
                    src: isScrolled ? logoLight : logoDark,
                    alt: 'TimeToClean',
                    className: 'logo-img'
                }),
                React.createElement(
                    'span',
                    { className: `logo-text ${isScrolled ? 'scrolled' : ''}` },
                    'Time To Clean'
                )
            ),
            React.createElement(
                'button',
                {
                    className: 'menu-toggle',
                    onClick: toggleMenu,
                    'aria-label': 'Toggle menu'
                },
                isMenuOpen ? '✕' : '☰'
            ),
            React.createElement(
                'nav',
                { className: `nav-menu ${isMenuOpen ? 'active' : ''}` },
                React.createElement(
                    Link,
                    {
                        to: '/',
                        className: `nav-link ${location.pathname === '/' ? 'active' : ''}`,
                        onClick: closeMenu
                    },
                    'Accueil'
                ),
                React.createElement(
                    Link,
                    {
                        to: '/contact',
                        className: `nav-link ${location.pathname === '/contact' ? 'active' : ''}`,
                        onClick: closeMenu
                    },
                    'Contact'
                )
            )
        )
    );
};

export default Header;
