import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import './Booking.css';

const Booking = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const serviceIdFromURL = searchParams.get('service');

  // Récupération de la liste des services depuis le backend
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(serviceIdFromURL || '');

  // Chargement des réservations existantes
  const [bookings, setBookings] = useState([]);

  // Créneau sélectionné et événement temporaire pour affichage dans FullCalendar
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [tempEvent, setTempEvent] = useState(null);

  // Formulaire d'informations client
  const [clientForm, setClientForm] = useState({
    name: '',
    email: '',
    phone: '',
    notes: ''
  });

  // Contrôles d'état
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Calcul de la date initiale du calendrier : si aujourd'hui est dimanche, on affiche lundi
  const today = new Date();
  let targetDate = today;
  if (today.getDay() === 0) {
    targetDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  }

  // Chargement des services
  useEffect(() => {
    fetch('https://backend.time-to-clean.com/api/services')
      .then(res => res.json())
      .then(data => {
        setServices(data);
        if (serviceIdFromURL && data.some(s => s._id === serviceIdFromURL)) {
          setSelectedServiceId(serviceIdFromURL);
        } else if (!serviceIdFromURL && data.length > 0) {
          setSelectedServiceId(data[0]._id);
        }
      })
      .catch(err => console.error('Erreur fetch services:', err));
  }, [serviceIdFromURL]);

  // Chargement des réservations
  useEffect(() => {
    fetch('https://backend.time-to-clean.com/api/bookings')
      .then(res => res.json())
      .then(data => setBookings(data))
      .catch(err => console.error('Erreur fetch bookings:', err));
  }, []);

  // Effet pour scroller en haut quand la réservation est confirmée
  useEffect(() => {
    if (success) {
      window.scrollTo(0, 0);
    }
  }, [success]);

  // Détermination du service sélectionné
  const selectedService = services.find(s => s._id === selectedServiceId) || null;

  // Conversion des réservations en événements pour FullCalendar
  const dbEvents = bookings.map(b => ({
    id: b._id,
    title: `${b.service ? b.service.name : 'Service inconnu'} - ${b.clientName}`,
    start: b.startTime,
    end: b.endTime,
    backgroundColor: '#3788d8'
  }));
  const allEvents = tempEvent ? [...dbEvents, tempEvent] : dbEvents;

  // Fonction de vérification de chevauchement
  const isOverlapping = (startA, endA, startB, endB) => {
    return startA < endB && endA > startB;
  };

  // Gestion du clic sur le calendrier
  const handleDateClick = (info) => {
    setError(null);
    const clickedDate = info.date;

    // Bloquer la sélection si la date cliquée est dans le passé
    if (clickedDate < new Date()) {
      setError('Impossible de sélectionner une date passée.');
      return;
    }

    if (!selectedService) {
      setError('Veuillez d’abord choisir une prestation.');
      return;
    }

    const start = clickedDate;
    const end = new Date(start.getTime() + selectedService.duration * 60000);
    const marginBefore = 30;
    const marginAfter = 30;
    const startMargin = new Date(start.getTime() - marginBefore * 60000);
    const endMargin = new Date(end.getTime() + marginAfter * 60000);

    for (let b of bookings) {
      const bStart = new Date(b.startTime);
      const bEnd = new Date(b.endTime);
      if (isOverlapping(startMargin, endMargin, bStart, bEnd)) {
        setError('Impossible de réserver ce créneau, il chevauche ou est trop proche d’une autre prestation.');
        return;
      }
    }
    setSelectedSlot({ start, end });
    const newTempEvent = {
      id: 'temp-selection',
      title: 'Créneau sélectionné',
      start,
      end,
      backgroundColor: '#f0ad4e',
      overlap: false
    };
    setTempEvent(newTempEvent);
  };

  // Gestion des modifications dans le formulaire client
  const handleClientFormChange = (e) => {
    const { name, value } = e.target;
    setClientForm(prev => ({ ...prev, [name]: value }));
  };

  // Soumission de la réservation
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!selectedService) {
        throw new Error('Aucun service sélectionné');
      }
      if (!selectedSlot) {
        throw new Error('Aucun créneau sélectionné');
      }
      const response = await fetch('https://backend.time-to-clean.com/api/bookings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          serviceId: selectedServiceId,
          startTime: selectedSlot.start.toISOString(),
          clientName: clientForm.name,
          clientEmail: clientForm.email,
          clientPhone: clientForm.phone,
          notes: clientForm.notes
        })
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Une erreur est survenue');
      }
      setSuccess(true);
    } catch (err) {
      setError(err.message);
      console.error('Erreur de réservation:', err);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <div className="booking-section">
        <div className="booking-container">
          <div className="success-message">
            <h3>Réservation confirmée !</h3>
            <p>
              Un email de confirmation vous a été envoyé avec les détails et les liens pour ajouter l'événement à votre calendrier.
            </p>
            <button onClick={() => navigate('/')} className="return-home-button">
              Retour à l'accueil
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="booking-section">
      <div className="booking-container">
        {selectedService ? (
          <h2>
            Réserver : {selectedService.name} — {selectedService.price}€ — {selectedService.duration} min
          </h2>
        ) : (
          <h2>Chargement du service...</h2>
        )}

        <div style={{ margin: '20px 0' }}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            initialDate={targetDate}
            slotDuration="00:30:00"
            slotMinTime="08:00:00"
            slotMaxTime="20:00:00"
            validRange={{ start: new Date() }}
            selectAllow={(selectInfo) => selectInfo.start >= new Date()}
            hiddenDays={[0]}  // Masque le dimanche
            contentHeight="auto"
            allDaySlot={false}
            selectable={true}
            dateClick={handleDateClick}
            events={allEvents}
            editable={false}
            locale={frLocale}
          />
        </div>

        {error && <div className="error-message">{error}</div>}

        {selectedSlot && (
          <div className="selected-slot-info">
            <p>
              <strong>Créneau choisi :</strong><br />
              Début : {selectedSlot.start.toLocaleString()}<br />
              Fin : {selectedSlot.end.toLocaleString()}
            </p>
          </div>
        )}

        {selectedSlot && (
          <form onSubmit={handleSubmit} className="booking-form">
            <div className="form-group">
              <label htmlFor="name">Nom complet</label>
              <input
                type="text"
                id="name"
                name="name"
                value={clientForm.name}
                onChange={handleClientFormChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={clientForm.email}
                onChange={handleClientFormChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Téléphone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={clientForm.phone}
                onChange={handleClientFormChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="notes">Notes supplémentaires</label>
              <textarea
                id="notes"
                name="notes"
                rows="3"
                value={clientForm.notes}
                onChange={handleClientFormChange}
              />
            </div>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? 'Réservation en cours...' : 'Confirmer la réservation'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Booking;
