import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCar, FaCouch, FaShower, FaChair, FaBed, FaClock, FaHome } from 'react-icons/fa';
import axios from 'axios';
import './Services.css';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeCategory, setActiveCategory] = useState("citadine");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        // Récupère tous les services, y compris leur catégorie
        const response = await axios.get('https://backend.time-to-clean.com/api/services');
        setServices(response.data);
      } catch (err) {
        setError('Erreur lors du chargement des services.');
      } finally {
        setLoading(false);
      }
    };
    fetchServices();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loading) return <div className="loading">Chargement des services...</div>;
  if (error) return <div className="error-message">{error}</div>;

  // Regroupement des services par catégorie en se basant sur service.category
  const categories = {
    citadine: services.filter(service => service.category && service.category.toLowerCase() === "citadine"),
    berline: services.filter(service => service.category && service.category.toLowerCase() === "berline"),
    suv: services.filter(service => service.category && service.category.toLowerCase() === "suv/monospace"),
    fauteuils: services.filter(service => service.category && service.category.toLowerCase() === "fauteuils"),
    canapes: services.filter(service => service.category && (service.category.toLowerCase() === "canapés" || service.category.toLowerCase() === "canapes")),
    matelas: services.filter(service => service.category && service.category.toLowerCase() === "matelas"),
    exterieur: services.filter(
      service =>
        service.category &&
        (service.category.toLowerCase() === "extérieur" || service.category.toLowerCase() === "exterieur")
    ),
    maison: services.filter(service => service.category && service.category.toLowerCase() === "maison")
  };

  // Ordre d'affichage des catégories (pour la vue mobile)
  const categoryOrder = ["citadine", "berline", "suv", "fauteuils", "canapes", "matelas", "exterieur", "maison"];

  // Retourne le titre et l'icône associée à une catégorie
  const getCategoryDisplay = (category) => {
    switch (category) {
      case "citadine":
        return { title: "Citadine", icon: <FaCar /> };
      case "berline":
        return { title: "Berline", icon: <FaCar /> };
      case "suv":
        return { title: "SUV/Monospace", icon: <FaCar /> };
      case "fauteuils":
        return { title: "Fauteuils", icon: <FaChair /> };
      case "canapes":
        return { title: "Canapés", icon: <FaCouch /> };
      case "matelas":
        return { title: "Matelas", icon: <FaBed /> };
      case "exterieur":
        return { title: "Nettoyage Extérieur", icon: <FaShower /> };
      case "maison":
        return { title: "Maison", icon: <FaHome /> };
      default:
        return { title: category, icon: null };
    }
  };

  return (
    <section className="services-section" id="services">
      <div className="services-container">
        <div className="section-header">
          <h2>Nos Services</h2>
          <p>Découvrez nos solutions de nettoyage écologiques et professionnelles</p>
        </div>
        {isMobile ? (
          // Vue mobile : affichage groupé par catégories avec titre et grille
          categoryOrder.map(categoryKey => {
            const catServices = categories[categoryKey];
            if (!catServices || catServices.length === 0) return null;
            const display = getCategoryDisplay(categoryKey);
            return (
              <div key={categoryKey}>
                <h3 className="category-title">
                  {display.icon} {display.title}
                </h3>
                <div className="services-grid">
                  {catServices.map(service => (
                    <div className="service-card" key={service._id}>
                      <div className="service-icon">
                        {display.icon}
                      </div>
                      <h3>{service.name}</h3>
                      <p>{service.description || "Service de nettoyage professionnel et écologique."}</p>
                      <div className="service-bottom">
                        <div className="service-duration">
                          <FaClock /> {service.duration} min
                        </div>
                        <div className="service-price">
                          Prix: {service.price}€
                        </div>
                        <Link to={`/booking?service=${service._id}`} className="service-cta">
                          Réserver maintenant
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })
        ) : (
          // Vue desktop : affichage avec onglets pour chaque catégorie
          <>
            <div className="service-tabs">
              {Object.keys(categories).map(category => {
                const display = getCategoryDisplay(category);
                return (
                  <button
                    key={category}
                    className={`tab-button ${activeCategory === category ? "active" : ""}`}
                    onClick={() => setActiveCategory(category)}
                  >
                    {display.icon} {display.title}
                  </button>
                );
              })}
            </div>
            <div className="services-grid">
              {(categories[activeCategory] || []).map(service => (
                <div className="service-card" key={service._id}>
                  <div className="service-icon">
                    {getCategoryDisplay(activeCategory).icon}
                  </div>
                  <h3>{service.name}</h3>
                  <p>{service.description || "Service de nettoyage professionnel et écologique."}</p>
                  <div className="service-bottom">
                    <div className="service-duration">
                      <FaClock /> {service.duration} min
                    </div>
                    <div className="service-price">
                      Prix: {service.price}€
                    </div>
                    <Link to={`/booking?service=${service._id}`} className="service-cta">
                      Réserver maintenant
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Services;
